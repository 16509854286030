<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      tokens: null,
    };
  },
  methods: {
    getList() {
      api.get("tokens").then((response) => {
        if (response.data.status == "success") {
          this.tokens = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Meus Tokens") }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Tokens</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!tokens || (tokens && tokens.length == 0)">
                    <td colspan="100%" class="text-center">
                      Nenhum registro encontrado.
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-for="token in tokens" :key="token.index">
                      <td>{{ token.id.split("-")[0] }}</td>
                      <td>{{ token.date }}</td>
                      <td>{{ token.value }}</td>
                      <td>
                        <span
                          v-if="token.status === 'active'"
                          class="badge badge-soft-success font-size-12"
                          >ATIVO</span
                        >
                        <span
                          v-else-if="token.status === 'inactive'"
                          class="badge badge-soft-warning font-size-12"
                          >INATIVO</span
                        >
                        <span
                          v-else-if="token.status === 'deleted'"
                          class="badge badge-soft-danger font-size-12"
                          >EXCLUÍDO</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
